<template>
  <div class="appBox">
    <h3>功能描述</h3>
    <div class="line"></div>
    <ul>
      <li>
        红狮启富APP是香港红狮集团自主研发的专业贵金属综合性交易软件，是专属于客户的智能理财服务平台。
      </li>
      <li>
        APP集开户、交易、存取款、实时报价、行情播报、财经资讯、专家分析、投教学习等功能于一身，让客户的交易方式更简单，及时把握最佳盈利机遇。
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name:'Functiong',
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
  }
}
</script>

<style lang="less" scoped>
  .appBox{
    width: 1138px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 0 110px;
    h3{
      font-size: 24px;
    }
    .line{
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      margin: 40px auto 60px;
    }
    ul{
      li{
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 40px;
        color: #6d6d6d;
        span{
          color: #fe0100;
        }
      }
    }
  }
</style>